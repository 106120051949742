.chip {
    /* display: inline-block; */
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgb(252, 144, 105);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: #e4e4e43f;
    margin: 0.5rem !important;
}

h1 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.userIcon {
    margin: .3rem;
}