.row {
  width: 100%;
  justify-content: space-between;
  color: white;
}

.logout_button {
  margin: 0.5rem !important;
}

.userIcon {
  font-size: 100%;
  margin-right: 0.5rem;
}

h6 {
  padding-left: 1rem;
}

.header {
  margin-top: 15px;
}