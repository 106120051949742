body {
    /* background-color: rgba(89, 41, 167, 0.5); */
    color: rgb(235, 115, 72);
    z-index: 0;
}

.btn {
    margin: 2rem;
}

.start {
    background-color: rgba(29, 122, 44, 0.685);
}

.stop {
    background-color: rgba(165, 42, 42, 0.534);
}

.card-content {
    padding: .5rem 0 0 0 !important ;
}

.card {
    background-color: rgba(255, 127, 80, 0.247);
    border-radius: .5rem;
}

.pts {
    font-size: 3rem;
}